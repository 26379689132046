<!-- 快乐8六区走势图 -->
<template>
  <div class="">
    <div class="ssq-table">
      <div class="ssq-table-header">
        <div class="ssq-icon">
          <svg
            t="1621310782405"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2538"
            width="45"
            height="45"
          >
            <path
              d="M541.408711 157.6704h122.3936l44.347733-84.1216-199.529244-1.803378-266.146133 660.383289h63.644444z"
              fill="#ffffff"
              p-id="2539"
            ></path>
            <path
              d="M585.764978 271.402667h127.689955l44.828445-90.641067h-203.374934l-264.220444 666.843022h61.715911z"
              fill="#ffffff"
              p-id="2540"
            ></path>
            <path
              d="M813.343289 294.408533h-210.759111l-270.427022 657.846045h209.251555l90.643911-205.960534h126.2336s207.414044 9.910044 234.416356-232.490666c0 0 11.5712-160.924444-179.359289-219.394845z m1.823289 260.667734c-49.169067 80.275911-133.046044 52.315022-133.046045 52.315022l65.558756-159.647289c116.656356 6.764089 67.487289 107.335111 67.487289 107.332267zM203.901156 506.587022c40.4992-92.020622 128.253156-54.129778 128.253155-54.129778l69.415822-158.048711c0-14.819556-323.487289-52.562489-369.316977 239.246223-17.3568 176.822044 177.874489 204.694756 177.874488 204.694755l51.646578-119.893333c-96.079644-59.630933-57.873067-111.869156-57.873066-111.869156z"
              fill="#ffffff"
              p-id="2541"
            ></path>
          </svg>
          <div class="ssq-one">
            <h2>中国福利彩票</h2>
          </div>
          <div class="ssq-two">
            <h3>快 乐 8 六 区 尾 数 走 势 图</h3>
          </div>
          <div class="clear"></div>
        </div>
      </div>
      <table class="tab" border="1" cellspacing="0" cellpadding="0">
        <thead class="thead-fixed">
          <tr>
            <th rowspan="2" class="ranks_a">期号</th>
            <th colspan="10" class="ranks_b">六 区 尾 数 走 势 图</th>
            <th colspan="3" class="ranks_b">六区012路</th>
            <th colspan="3" class="ranks_b">六区147路</th>
            <th colspan="3" class="ranks_b">六区258路</th>
            <th colspan="2" class="ranks_b">奇 偶</th>
            <th colspan="2" class="ranks_b">大 小</th>
            <th colspan="2" class="ranks_b">质 合</th>
            <th colspan="10" class="ranks_b">六区出号个数</th>
            <th colspan="3" class="ranks_b">个数012路</th>
            <th colspan="3" class="ranks_b">个数147路</th>
            <th colspan="3" class="ranks_b">个数258路</th>
            <th colspan="2" class="ranks_b">奇 偶</th>
            <th colspan="2" class="ranks_b">大 小</th>
            <th colspan="2" class="ranks_b">质 合</th>
            <th colspan="5" class="ranks_b">六区五行走势图</th>
          </tr>
          <tr>
            <th class="ranks_c">0</th>
            <th class="ranks_c">1</th>
            <th class="ranks_c">2</th>
            <th class="ranks_c">3</th>
            <th class="ranks_c">4</th>
            <th class="ranks_c">5</th>
            <th class="ranks_c">6</th>
            <th class="ranks_c">7</th>
            <th class="ranks_c">8</th>
            <th class="ranks_c">9</th>
            <th class="ranks_c">0</th>
            <th class="ranks_c">1</th>
            <th class="ranks_c">2</th>
            <th class="ranks_c">1</th>
            <th class="ranks_c">4</th>
            <th class="ranks_c">7</th>
            <th class="ranks_c">2</th>
            <th class="ranks_c">5</th>
            <th class="ranks_c">8</th>
            <th class="ranks_c">奇</th>
            <th class="ranks_c">偶</th>
            <th class="ranks_c">大</th>
            <th class="ranks_c">小</th>
            <th class="ranks_c">质</th>
            <th class="ranks_c">合</th>
            <th class="ranks_c">0</th>
            <th class="ranks_c">1</th>
            <th class="ranks_c">2</th>
            <th class="ranks_c">3</th>
            <th class="ranks_c">4</th>
            <th class="ranks_c">5</th>
            <th class="ranks_c">6</th>
            <th class="ranks_c">7</th>
            <th class="ranks_c">8</th>
            <th class="ranks_c">9</th>
            <th class="ranks_c">0</th>
            <th class="ranks_c">1</th>
            <th class="ranks_c">2</th>
            <th class="ranks_c">1</th>
            <th class="ranks_c">4</th>
            <th class="ranks_c">7</th>
            <th class="ranks_c">2</th>
            <th class="ranks_c">5</th>
            <th class="ranks_c">8</th>
            <th class="ranks_c">奇</th>
            <th class="ranks_c">偶</th>
            <th class="ranks_c">大</th>
            <th class="ranks_c">小</th>
            <th class="ranks_c">质</th>
            <th class="ranks_c">合</th>
            <th class="ranks_c">金</th>
            <th class="ranks_c">木</th>
            <th class="ranks_c">水</th>
            <th class="ranks_c">火</th>
            <th class="ranks_c">土</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item2, x) in klb" :key="x">
            <td>{{ item2.expect }}</td>
            <!-- 一区尾数 -->
            <td class="b-red" v-for="(item, i) in red" :key="i">
              <div
                :class="item == item1 ? 'q-red' : 'no'"
                v-for="(item1, j) in red0[x]"
                :key="j"
              >
                {{ item == item1 ? item : "" }}
              </div>
            </td>
            <!-- 012路 -->
            <td class="b-lan" v-for="(item, i) in lye" :key="i">
              <div
                :class="item1 == item ? 'q-lan' : 'no'"
                v-for="(item1, j) in lye1[x]"
                :key="j"
              >
                {{ item1 == item ? item : "" }}
              </div>
            </td>
            <!-- 147路 -->
            <td class="b-red" v-for="(item, i) in ysq" :key="i">
              <div
                :class="item1 == item ? 'q-red' : 'no'"
                v-for="(item1, j) in ysq1[x]"
                :key="j"
              >
                {{ item1 == item ? item : "" }}
              </div>
            </td>
            <!-- 258路 -->
            <td class="b-lan" v-for="(item, i) in ewb" :key="i">
              <div
                :class="item1 == item ? 'q-lan' : 'no'"
                v-for="(item1, j) in ewb1[x]"
                :key="j"
              >
                {{ item1 == item ? item : "" }}
              </div>
            </td>
            <!-- 一区奇偶 -->
            <td class="b-red" v-for="(item, i) in jo" :key="i">
              <div
                :class="item1 == item ? 'q-red' : 'no'"
                v-for="(item1, j) in jo1[x]"
                :key="j"
              >
                {{ item1 == item ? item : "" }}
              </div>
            </td>
            <!-- 一区大小 -->
            <td class="b-lan" v-for="(item, i) in dx" :key="i">
              <div
                :class="item1 == item ? 'q-lan' : 'no'"
                v-for="(item1, j) in dx1[x]"
                :key="j"
              >
                {{ item1 == item ? item : "" }}
              </div>
            </td>
            <!-- 一区质合 -->
            <td class="b-red" v-for="(item, i) in zh" :key="i">
              <div
                :class="item1 == item ? 'q-red' : 'no'"
                v-for="(item1, j) in zh1[x]"
                :key="j"
              >
                {{ item1 == item ? item : "" }}
              </div>
            </td>
            <!-- 一区个数 -->
            <td class="b-lan" v-for="(item, i) in zf" :key="i">
              <div :class="gs1[x] == item ? 'q-lan' : 'no'"  >
                {{ gs1[x] == item ? item : "" }}
              </div>
            </td>
            <!-- 个数012路 -->
            <td class="b-red" v-for="(item, i) in lye" :key="i">
               <div :class="gs1[x]%3 == item ? 'q-red' : ''"  >
                {{ gs1[x]%3 == item ? item : "" }}
              </div>
            </td>
            <!-- 个数147路 -->
            <td class="b-lan" v-for="(item, i) in ysq" :key="i">
              <div :class="(([1,5,6,9].indexOf(gs1[x])==-1 ? '' : 1)||([0,2,4].indexOf(gs1[x])==-1 ? '' : 4)||([3,7,8].indexOf(gs1[x])==-1 ? '' : 7)) == item ? 'q-lan' : '' "  >
                {{ (([1,5,6,9].indexOf(gs1[x])==-1 ? '' : 1)||([0,2,4].indexOf(gs1[x])==-1 ? '' : 4)||([3,7,8].indexOf(gs1[x])==-1 ? '' : 7)) == item ? item : "" }}
              </div>
            </td>
            <!-- 个数258路 -->
            <td class="b-red" v-for="(item, i) in ewb" :key="i">
              <div :class="(([2,7,9].indexOf(gs1[x])==-1 ? '' : 2)||([0,3,5].indexOf(gs1[x])==-1 ? '' : 5)||([1,4,6,8].indexOf(gs1[x])==-1 ? '' : 8)) == item ? 'q-red' : ''  "  >
                {{ (([2,7,9].indexOf(gs1[x])==-1 ? '' : 2)||([0,3,5].indexOf(gs1[x])==-1 ? '' : 5)||([1,4,6,8].indexOf(gs1[x])==-1 ? '' : 8)) == item ? item : '' }}
              </div>
            </td>
            <!-- 个数奇偶 -->
            <td class="b-lan" v-for="(item, i) in jo" :key="i">
              <div :class=" (gs1[x]%2==0 ? '偶':'奇') ==item ? 'q-lan' : '' ">
                {{ (gs1[x]%2==0 ? '偶':'奇') ==item ? item : ''}} 
              </div>
            </td>
            <!-- 个数大小 -->
            <td class="b-red" v-for="(item, i) in dx" :key="i">
              <div :class=" (gs1[x]>4 ? '大':'小') ==item ? 'q-red' : '' ">
                {{ (gs1[x]>4 ? '大':'小') ==item ? item : ''}} 
              </div>
            </td>
            <!-- 个数质合 -->
            <td class="b-lan" v-for="(item, i) in zh" :key="i">
              <div :class="([1,2,3,5,7].indexOf(gs1[x])==-1 ? '合' : '质') == item ? 'q-lan' : ''  "  >
                {{ ([1,2,3,5,7].indexOf(gs1[x])==-1 ? '合' : '质') == item ? item : '' }}
              </div>
            </td>
            <!-- 一区五行走势图 -->
            <td class="b-red" v-for="(item, i) in wx" :key="i">
              <div :class="  item ==(1==gs1[x]||9==gs1[x]? '金':''||2==gs1[x]||8==gs1[x]? '木':''||
              3==gs1[x]||7==gs1[x]? '水':''||
              4==gs1[x]||6==gs1[x]?'火':''||0==gs1[x]||5==gs1[x]?'土':'')? 'q-red' : '' ">
                  {{ item ==(1==gs1[x]||9==gs1[x]? '金':''||2==gs1[x]||8==gs1[x]? '木':''||
                  3==gs1[x]||7==gs1[x]? '水':''||
                  4==gs1[x]||6==gs1[x]?'火':''||0==gs1[x]||5==gs1[x]?'土':'')? item : '' }}
              </div>
            </td>
          </tr>
          <tr>
            <td>待开奖</td>
            <td v-for="(x, i) in 55" :key="i">
              <div :class="x == i + 1 ? 'xuan' : ''">
                {{ "?" }}
              </div>
            </td>
          </tr>
          <tr>
            <td>待开奖</td>
            <td v-for="(x, i) in 55" :key="i">
              <div :class="x == i + 1 ? 'xuan' : ''">
                {{ "?" }}
              </div>
            </td>
          </tr>
          <tr>
            <td>待开奖</td>
            <td v-for="(x, i) in 55" :key="i">
              <div :class="x == i + 1 ? 'xuan' : ''">
                {{ "?" }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
 import mitt from '@/js/mitt.js'
export default {
  name: "red-tail",
  components: {},
  data() {
    // 数据源
    return {
      q1: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
        39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
        57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74,
        75, 76, 77, 78, 79, 80,
      ],
      red: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      red0: [],
      lye: [0, 1, 2],
      lye1: [],
      ysq: [1, 4, 7],
      ysq1: [],
      ewb: [2, 5, 8],
      ewb1: [],
      jo: ["奇", "偶"],
      jo1: [],
      dx: ["大", "小"],
      dx1: [],
      zh: ["质", "合"],
      zh1: [],
      zf: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      gs1: [],
      wx: ["金", "木", "水", "火", "土"],
      wx1: [],
    
    };
  },

  mounted() {
    this.mred();
    this.mlye();
    this.mysq();
    this.mewb();
    this.mjo();
    this.mdx();
    this.mzh();
    this.mgs();
    this.find()
  },
  computed: {
    klb() {
      return this.$store.state.klb;
    },
  },

  methods: {
    // 函数体部分，js的主要逻辑控制
    find() {
        document.documentElement.scrollTop = 0;
        if (this.klb == '') {
          mitt.emit('klb');
        }
      },
    mred() { //出号尾数
      this.red0=[]
      for (var i = 0; i < this.klb.length; i++) {
        var xx = new Array();
        for (var j = 0; j < this.klb[i].red.length; j++) {
          if (this.klb[i].red[j] <= 60 && this.klb[i].red[j] > 50) {
            if(xx.indexOf(this.klb[i].red[j]%10)==-1){
              xx.push(this.klb[i].red[j] % 10);
            
            }
          }
        }
        this.red0.push(xx);
        //console.log(this.red0);
      }
      // console.log(this.red0)
      //return xx
    },

    mlye() { //012路
      this.lye1=[]
      for (var i = 0; i < this.red0.length; i++) {
        var xx = new Array();
        for (var j = 0; j < this.red0[i].length; j++) {
          if (xx.indexOf(this.red0[i][j] % 3) == -1) {
            xx.push(this.red0[i][j] % 3);
          }
        }
        this.lye1.push(xx);
      }
    },

    mysq() {  //147路
      this.ysq1=[]
      for (var i = 0; i < this.red0.length; i++) {
        var xx = new Array();
        for (var j = 0; j < this.red0[i].length; j++) {
          if (
            this.red0[i][j] == 1 ||
            this.red0[i][j] == 5 ||
            this.red0[i][j] == 6 ||
            this.red0[i][j] == 9
          ) {
            if (xx.indexOf(1) == -1) {
              xx.push(1);
            }
          } else if (
            this.red0[i][j] == 0 ||
            this.red0[i][j] == 2 ||
            this.red0[i][j] == 4
          ) {
            if (xx.indexOf(4) == -1) {
              xx.push(4);
            }
          } else if (
            this.red0[i][j] == 3 ||
            this.red0[i][j] == 7 ||
            this.red0[i][j] == 8
          ) {
            if (xx.indexOf(7) == -1) {
              xx.push(7);
            }
          }
        }
        this.ysq1.push(xx);
      }
    },

    mewb() {  //258路
      this.ewb1=[]
      for (var i = 0; i < this.red0.length; i++) {
        var xx = new Array();
        for (var j = 0; j < this.red0[i].length; j++) {
          if (
            this.red0[i][j] == 2 ||
            this.red0[i][j] == 7 ||
            this.red0[i][j] == 9
          ) {
            if (xx.indexOf(2) == -1) {
              xx.push(2);
            }
          } else if (
            this.red0[i][j] == 0 ||
            this.red0[i][j] == 3 ||
            this.red0[i][j] == 5
          ) {
            if (xx.indexOf(5) == -1) {
              xx.push(5);
            }
          } else if (
            this.red0[i][j] == 1 ||
            this.red0[i][j] == 4 ||
            this.red0[i][j] == 6 ||
            this.red0[i][j] == 8
          ) {
            if (xx.indexOf(8) == -1) {
              xx.push(8);
            }
          }
        }
        this.ewb1.push(xx);
      }
    },

    mjo() {  //奇偶
      this.jo1=[]
      for (var i = 0; i < this.red0.length; i++) {
        var xx = new Array();
        for (var j = 0; j < this.red0[i].length; j++) {
          if (this.red0[i][j] % 2 == 0) {
            if (xx.indexOf("偶") == -1) {
              xx.push("偶");
            }
          } else {
            if (xx.indexOf("奇") == -1) {
              xx.push("奇");
            }
          }
        }
        this.jo1.push(xx);
      }
    },

    mdx() {  //大小
      this.dx1=[]
      for (var i = 0; i < this.red0.length; i++) {
        var xx = new Array();
        for (var j = 0; j < this.red0[i].length; j++) {
          if (this.red0[i][j] < 5) {
            if (xx.indexOf("小") == -1) {
              xx.push("小");
            }
          } else {
            if (xx.indexOf("大") == -1) {
              xx.push("大");
            }
          }
        }
        this.dx1.push(xx);
      }
    },

    mzh() {  //质合
      this.zh1=[]
      for (var i = 0; i < this.red0.length; i++) {
        var xx = new Array();
        for (var j = 0; j < this.red0[i].length; j++) {
          if ([1, 2, 3, 5, 7].indexOf(this.red0[i][j]) == -1) {
            if (xx.indexOf("合") == -1) {
              xx.push("合");
            }
          } else {
            if (xx.indexOf("质") == -1) {
              xx.push("质");
            }
          }
        }
        this.zh1.push(xx);
      }
    },

    mgs() {  //个数
      this.gs1=[]
      for (var i = 0; i < this.klb.length; i++) {
        var xx = new Array();
        for (var j = 0; j < this.klb[i].red.length; j++) {
          if (this.klb[i].red[j] <= 60 && this.klb[i].red[j] > 50) {
            xx+=1
          }
        }
        this.gs1.push(xx.length);
       // console.log(this.gs1);
      }
      // console.log(this.red0)
      //return xx
    },
  },
  watch: {
      klb() {
        this.mred();
        this.mlye();
        this.mysq();
        this.mewb();
        this.mjo();
        this.mdx();
        this.mzh();
        this.mgs();
      }
    }
};
</script>

<style scoped>
/* @import './swiper.css'; */
.ssq-table-header {
  width: 100%;
  height: 50px;
  background-color: red;
}

.tab {
  margin: 0 auto;
  border-collapse: collapse;
}

.thead-fixed th {
  border: 1px solid #808080;
}

/* .ranks_a {
        width: 60px;
    } */

.ranks_a1 {
  width: 25px;
}

.ranks_c {
  width: 23px;
  color: red;
}

.ranks_d {
  width: 22px;
  color: blue;
}

.ssq-icon {
  text-align: left;
}

.icon {
  float: left;
}

.ssq-one {
  float: left;
  height: 42px;
  line-height: 42px;
  color: #fff;
}

.ssq-two {
  float: left;
  width: 80%;
  text-align: center;
  line-height: 42px;
  font-size: 25px;
  color: rgb(252, 251, 249);
}

tbody .red {
  background-color: red;
  border-radius: 15px;
  color: #fff;
}

tbody .xuan {
  background-color: rgb(160, 156, 156);
  border-radius: 15px;
  color: rgb(253, 253, 253);
}

tbody .lan {
  background-color: blue;
  color: #fff;
  border-radius: 15px;
}

.b-red {
  height: 22px;
  background-color: rgb(191, 224, 224);
}

.b-lan {
  height: 22px;
  background-color: rgb(202, 191, 224);
}

.q-red {
  background-color: rgb(212, 146, 3);
  border-radius: 15px;
  color: #fff;
}

.q-lan {
  background-color: rgb(60, 117, 52);
  border-radius: 15px;
  color: #fff;
}
.no {
  display: none;
}
</style>